import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LazyImage from 'components/shared/LazyImage';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Content from 'components/shared/Content';
import heroKV from 'assets/icons/kv-hero.svg';
import prokocimLogo from 'assets/icons/logo-prokocim-2-white.svg';
import useMedia from 'hooks/useMedia';
import scrollToSection from 'utils/scrollToSection';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';

export const getRomanStageByNumber = number => {
  switch (number) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    case 4:
      return 'IV';
    case 5:
      return 'V';
    case 6:
      return 'VI';
    default:
      return 'I';
  }
};

const Wrapper = styled.header`
  position: relative;
  border-top: ${({ theme }) => theme.navSize.mobile} solid #ffffff;
  ${({ theme }) => theme.mq.lg} {
    border-top: ${({ theme }) => theme.navSize.desktop} solid #ffffff;
  }
`;

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 0;
    height: 500px;
    flex-direction: row;
  }
  ${({ theme }) => theme.mq.xxl} {
    height: 650px;
  }
`;

const GreenBackground = styled.div`
  position: relative;
  width: 350px;
  height: 100%;
  background: ${({ theme }) => theme.colors.lightGreen300};

  ${({ theme }) => theme.mq.md} {
    width: 50%;
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 260px;
  ${({ theme }) => theme.mq.md} {
    margin-left: auto;
    height: 100%;
    width: 50%;
  }
`;

const ContentWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.md} {
    display: block;
    width: 100%;
    /* height: 100%; */
    /* min-height: 250px; */
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.text};
    padding: 95px 0 0;

    ${Content} {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  padding: 20px 0 0;
  color: #fff;
  position: relative;
  width: 100%;
  z-index: 2;
  background: ${({ theme }) => theme.colors.lightGreen300};
  ${({ theme }) => theme.mq.md} {
    padding: 0;
    display: block;
    position: absolute;
    left: 42%;
    bottom: 0;
    transform: translateX(-50%);
    width: clamp(350px, 40vw, 465px);
    background: transparent;
  }

  ${({ theme }) => theme.mq.xxl} {
    width: 620px;
    left: 40%;
  }
`;

const MobileContent = styled(Content)`
  position: relative;
  z-index: 2;
  font-size: 13px;
  margin-bottom: 40px;
  @media (max-width: 400px) {
    padding-top: 30px;
    font-size: 15px;
    margin-bottom: 50px;
    padding-left: 30px;
  }
  span {
    font-size: 16px;
    font-weight: 700;
    margin: 5px 0;
  }
  div:first-child {
    font-size: 18px;
  }

  div + div {
    padding-top: 20px;
  }
  button {
    margin-top: 15px;
  }
  ${({ theme }) => theme.mq.md} {
    display: none;
  }
`;

const DesktopContent = styled.div`
  display: none;

  position: absolute;
  bottom: 10px;
  left: 0;
  transform: translateX(-65%);
  font-size: 18px;

  ${({ theme }) => theme.mq.md} {
    display: block;
  }

  ${({ theme }) => theme.mq.lg} {
    font-size: 22px;
    bottom: 10px;
    transform: translateX(-75%);
  }

  ${({ theme }) => theme.mq.xxl} {
    font-size: 28px;
    bottom: 14px;
    transform: translateX(-75%);
  }
`;

const ImageInnerWrapper = styled.div`
  @media (max-width: 400px) {
    display: none;
  }
  position: relative;
  z-index: 2;
  min-width: 200px;
  height: 240px;
  ${({ theme }) => theme.mq.md} {
    width: 100%;
    height: 100%;
  }
`;

const LogoWrapper = styled.div`
  display: none;

  position: absolute;
  top: 30px;
  left: 0;
  transform: translateX(-65%);
  width: clamp(160px, 20vw, 230px);
  height: auto;

  ${({ theme }) => theme.mq.md} {
    display: block;
  }

  ${({ theme }) => theme.mq.xxl} {
    top: 55px;
    width: 270px;
  }
`;

const ContentBottom = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -30px;
  right: 10%;
  transform: translateX(10%);
  z-index: 4;
  width: 100%;
  max-width: 340px;
  padding: 0 40px;
  ${({ theme }) => theme.mq.md} {
    top: -30px;
    right: 25%;
    transform: translateX(50%);
    padding: 0;
  }
  button {
    height: 60px;
    max-width: none;
    &:before,
    &:after {
      border-top-width: 30px;
      border-bottom-width: 30px;
    }
  }
`;

const TimelineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;

  ${({ theme }) => theme.mq.lg} {
    padding-top: 0;
  }
`;

const TimelineInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 35px 25px;

  ${({ theme }) => theme.mq.lg} {
    padding-top: 90px;
    padding-left: 0;
    padding-right: 0;
  }

  ${({ theme }) => theme.mq.xl} {
    padding-top: 115px;
    padding-bottom: 35px;
  }
`;

const Timeline = styled.div`
  position: relative;
  width: 100%;
  max-width: 480px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: calc(100% + 20px);
    background-color: ${({ theme }) => theme.colors.gray300};
  }

  ${({ theme }) => theme.mq.lg} {
    max-width: 580px;
  }

  ${({ theme }) => theme.mq.xl} {
    display: flex;
    align-items: flex-start;
    padding: 0 16px;
    max-width: unset;

    &:before {
      top: 65px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 128px);
      height: 1px;
      background-color: ${({ theme }) => theme.colors.gray300};
    }
  }
`;

const StageItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  align-items: center;
  width: 100%;
  column-gap: 10px;

  :not(:last-child) {
    margin-bottom: 20px;
  }

  div:last-child {
    p {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  ${({ theme }) => theme.mq.xl} {
    display: flex;
    flex-direction: column;
    align-items: center;

    :not(:last-child) {
      margin-bottom: 0;
      margin-right: 30px;
    }
  }
`;

const StageTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #90c155;
  height: 38px;
  width: 100%;

  p {
    font-size: 16px;
    color: #ffffff;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #4e8f3d;
    `}

  ${({ theme }) => theme.mq.lg} {
    height: 40px;
  }
`;

const Text = styled.p`
  ${({ theme }) => theme.mq.xl} {
    text-align: center;
  }
`;

const StageSeparatorPart = styled.div`
  position: relative;
  justify-self: center;
  width: 20px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray300};

  ${({ theme }) => theme.mq.xl} {
    width: 1px;
    height: 20px;
    margin: 15px 0;
  }
`;

const KV = styled.div`
  display: none;

  position: absolute;
  top: 0;
  right: -1px;
  pointer-events: none;

  ${({ theme }) => theme.mq.lg} {
    display: block;
    width: 86%;
    z-index: 1;
  }
`;

const AnnouncementWrapper = styled.div`
  width: fit-content;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f47920;
`;
export const AnnouncementMobileWrapper = styled(AnnouncementWrapper)`
  margin-top: 60px;

  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;
export const AnnouncementDesktopWrapper = styled(AnnouncementWrapper)`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
    width: 100%;
    transform: translateY(-50%);
    p {
      color: #ffffff;
      text-align: center;
    }
  }
`;
export const AnnouncementHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  p {
    color: #ffffff;
    font-weight: 600;
  }
`;

const Header = ({
  image,
  content1,
  content2,
  background,
  backgroundAlt,
  timeline,
}) => {
  const matchesXL = useMedia('(min-width: 1200px)');

  const handleButtonClick = () => {
    scrollToSection('#mieszkania');
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <GreenBackground />
        <BackgroundWrapper>
          <LazyImage src={background} alt={backgroundAlt} cover />
        </BackgroundWrapper>
        <ImageWrapper>
          <ImageInnerWrapper>
            <LazyImage src={image} alt="girl on motorcycle" />
          </ImageInnerWrapper>
          <MobileContent>
            <div dangerouslySetInnerHTML={{ __html: content1 }} />
            <div
              dangerouslySetInnerHTML={{
                __html: content2,
              }}
            />
          </MobileContent>
          <DesktopContent>
            <p dangerouslySetInnerHTML={{ __html: content1 }} />
          </DesktopContent>
          <LogoWrapper>
            <Icon src={prokocimLogo} alt="prokocim logo" full />
          </LogoWrapper>
        </ImageWrapper>
        <KV aria-hidden>
          <Icon src={heroKV} alt="key visual" full />
        </KV>
      </InnerWrapper>

      <ContentBottom>
        <ButtonWrapper>
          <Button
            onClick={() => {
              emitMatomoEvent({
                event: eventsNames.click_wybierz_mieszkanie,
              });
              handleButtonClick();
            }}
          >
            Wybierz mieszkanie
          </Button>
        </ButtonWrapper>

        <ContentWrapper>
          <Content>
            {/* <AnnouncementDesktopWrapper>
              <AnnouncementHeadingWrapper>
                <Text
                  dangerouslySetInnerHTML={{
                    __html:
                      'Informujemy, że w dniach 16-18 stycznia biuro sprzedaży w Krakowie będzie nieczynne',
                  }}
                />
              </AnnouncementHeadingWrapper>
            </AnnouncementDesktopWrapper> */}
            <p dangerouslySetInnerHTML={{ __html: content2 }} />
          </Content>
        </ContentWrapper>
      </ContentBottom>
      {/* <AnnouncementMobileWrapper>
        <AnnouncementHeadingWrapper>
          <Text
            dangerouslySetInnerHTML={{
              __html:
                'Informujemy, że w dniach 16-18 stycznia biuro sprzedaży w Krakowie będzie nieczynne',
            }}
          />
        </AnnouncementHeadingWrapper>
      </AnnouncementMobileWrapper> */}
      <Content>
        <TimelineWrapper>
          <TimelineInnerWrapper>
            <Timeline>
              {timeline.map((stage, index) => (
                <StageItem key={`stage-item-${index}`}>
                  <StageTile active={stage.active}>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: getRomanStageByNumber(index + 1),
                      }}
                    />
                  </StageTile>
                  <StageSeparatorPart />
                  <div>
                    <Text dangerouslySetInnerHTML={{ __html: stage.title }} />
                    {stage.signature && (
                      <Text
                        dangerouslySetInnerHTML={{ __html: stage.signature }}
                      />
                    )}
                  </div>
                </StageItem>
              ))}
            </Timeline>
          </TimelineInnerWrapper>
        </TimelineWrapper>
      </Content>
    </Wrapper>
  );
};

Header.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  background: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  backgroundAlt: PropTypes.string.isRequired,
  content1: PropTypes.string.isRequired,
  content2: PropTypes.string.isRequired,
  timeline: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Header;
